@import url('https://fonts.googleapis.com/css2?family=Figtree:ital,wght@0,300;0,400;0,500;0,600;0,700;1,400;1,500;1,600;1,700&family=Mulish:ital,wght@0,200..1000;1,200..1000&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Space+Mono:ital,wght@0,400;0,700;1,400;1,700&display=swap');
@font-face {
    font-family: 'PPNeueMontreal';
    src: url('../public/PPNeueMontreal-Regular.ttf') format('truetype');
}
@font-face {
  font-family: 'EuclidCircularA';
  src: url('../public/EuclidCircularA-Regular.ttf') format('truetype');
}
@import url('https://fonts.googleapis.com/css2?family=Inter:ital,opsz,wght@0,14..32,100..900;1,14..32,100..900&display=swap');

:root {
  --color-primary: #51AD7D;
  --color-wayfaster: #F2DA00;
  --color-wayfaster-dark: #C2A800;
  --color-wayfaster-bg: #fff6ad;
  --color-background-dark: #2E2E2E;
  --color-video-bg: #27272A;
  --color-background-dark-secondary: #585858;
  --color-background-light: #FFFFFF;
  --color-background-light-secondary: #F2F6F9;
  --color-border-gray: #E4E4E7;
  --color-text-on-dark: #C3C3C3;
  --color-text-on-light: #0B0B0B;
  --color-gray: #757F7F;
  --color-empty: #F8F8F8;
  --color-lightgray: #EDEDED;
  --color-required: #FF0825;

  --color-text-white: #FFFFFF;
  --color-text-inactive: #D1D5DB;
  --color-light-bg: #FAFAFA;
  --color-dark-gray-bg: #3F3F46;
  --color-dark-bg: #18181B;
  --color-text-main: #0B0B0B;
  --color-text-gray: #6B7280;
  --color-red: #DC2626;
  --color-border-light: #E5E7EB;
  --color-border-focus: #364153;
}

body {
    background-color: white;
    color: 'black';
    font-family: 'figtree', sans-serif;
    width: 100%;
    height: 100%;
}

html {
  width: 100%;
  height: 100%;
}

h1, h2, h3, h4, h5 {
  color: #151F20;
  font-weight: 600;
  margin: 0;
}

h3.phone-h3 {
  font-size: 18px;
  line-height: 28px;
}

h4.phone-h4 {
  font-size: 16px;
  line-height: 24px;
}

p.phone-p {
  color: #4B5563;
  margin: 0;
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
}

.job-title-header {
  font-size: 16px;
  line-height: 20px;
  font-weight: 500;
  color: var(--color-text-main);
  margin-bottom: 4px;
}

.job-title-description {
  font-size: 14px;
  line-height: 20px;
  font-weight: 400;
  color: var(--color-gray);
  margin: 0;
}

.job-text-description-header {
  font-size: 14px;
  line-height: 20px;
  font-weight: 500;
  margin-bottom: 12px;
  color: var(--color-text-main);
}

.job-text-description {
  font-size: 14px;
  line-height: 28px;
  font-weight: 400;
  color: var(--color-text-main);
}

ul.job-text-description {
  margin: 0;
  margin-block-start: 0;
  padding-left: 24px;
}


.tag-main {
  box-sizing: border-box;
  height: 40px;
  padding: 8px;
  border-radius: 8px;
  border: 1px solid var(--color-border-light);
}

.font-circular {
  font-family: 'EuclidCircularA' !important;
}

.font-inter {
  font-family: 'Inter' !important;
}

.text-center {
  text-align: center;
}

.mb-8 {
  margin-bottom: 8px;
}

.m-auto {
  margin: auto;
}

.d-flex {
  display: flex;
}

.flex-col {
  flex-direction: column;
}

.flex-row {
  flex-direction: row;
}

.mx-auto {
  margin-left: auto;
  margin-right: auto;
}

.my-auto {
  margin-top: auto;
  margin-bottom: auto;
}

.card {
  border-radius: 8px;
}

.card.border {
  border: 1px solid lightgray;
}

.p-16 {
  padding: 16px;
}

.p-24 {
  padding: 24px;
}

.text-left {
  text-align: left;
}

.text-right {
  text-align: right;
}

.color-gray {
  color: var(--color-gray);
}

.timer-container {
  height: 36px;
  border-radius: 8px;
  background-color: #DCFCE7;
  color: #14532D;
  padding: 0px 8px;
}

.timer-container p {
  margin: 0;
  color: #14532D;
}

.timer-container-dark {
  height: 40px;
  border-radius: 8px;
  background-color: var(--color-dark-bg);
  color: var(--color-text-white);
  padding: 0px 8px;
}

.timer-container p {
  margin: 0;
  color: #14532D;
}

.onboard-icon-container {
  margin: auto;
  width: 40px;
  height: 40px;
  border-radius: 8px;
  margin-bottom: 20px;
}

.onboard-icon-container img {
  margin: auto;
}

.onboard-icon-container.star {
  background-color: #EFF6FF;
}

.onboard-icon-container.play {
  background-color: #FEFCE8;
}

.onboard-icon-container.mic {
  background-color: #F0FDF4;
}

.animated-check {
  height: 10em;
  width: 10em
}

.animated-check path {
  fill: none;
  stroke: var(--color-wayfaster);
  stroke-width: 4;
  stroke-dasharray: 23;
  stroke-dashoffset: 23;
  animation: draw 0.5s ease-in-out forwards;
  stroke-linecap: round;
  stroke-linejoin: round
}

@keyframes draw {
  to {
      stroke-dashoffset: 0
  }
}

.clickable-grow {
  cursor: pointer;
  transition: 0.2s;
}
.clickable-grow:hover {
  transform: scale(1.025);
}

.video-feed-mirrored {
  transform: rotateY(180deg);
  -webkit-transform:rotateY(180deg); /* Safari and Chrome */
  -moz-transform:rotateY(180deg); /* Firefox */
  transition: all 0.2s;
}

.candidate-video-feed {
  transition: all 0.2s;
}

.candidate-video-feed.speaking {
  border: 4px solid var(--color-wayfaster);
}

.candidate-interview-container {
  display: flex;
  flex-direction: column;
  height: 100vh;
  width: 100%;
  overflow: hidden;
  background-color: var(--color-dark-bg);
}

.ai-speaker-card {
  background-color: var(--color-video-bg);
  border: 1px solid transparent;
  border-radius: 16px;
  align-items: center;
  padding: 16px;
  transition: all 0.2s;
  opacity: 1;
  z-index: 1005;
  flex: 1;
}

.ai-speaker-card.speaking {
  border: 4px solid var(--color-wayfaster);
}

.ai-speaker-card.disconnected {
  opacity: 0.5;
}

.ai-speaker-card h3 {
  color: #fff;
}

.transcript-bubble {
  background-color: var(--color-dark-gray-bg);
  padding: 8px 12px;
  border-radius: 12px;
}

.transcript-bubble p {
  margin: 0;
}

.transcript-bubble.interviewer {
  background-color: var(--color-dark-gray-bg);
  color: var(--color-text-white);
  border-top-left-radius: 0 !important;
  margin-right: auto;
  width: fit-content;
  max-width: 80%;
}

.transcript-bubble.candidate {
  background-color: var(--color-dark-gray-bg);
  color: var(--color-text-white);
  border-top-right-radius: 0 !important;
  margin-left: auto;
  width: fit-content;
  max-width: 80%;
}

.whiteboardView .button-initial {
    background-color: white; /* White background */
    color: black;           /* Black text */
    border-radius: 10px;  
    font-weight: 400; 
    font-size: 12; 
    padding-left: 11vh;
    padding-right: 11vh;
    text-align: center;
    text-transform: none /* Rounded corners, adjust as needed */
  }
  
.whiteboardView .button-active {
    background-color: #66C097; /* Green background */
    color: #fff;    
    font-weight: 400; 
    font-size: 12; 
    padding-left: 10vh;
    padding-right: 10vh;        /* Black text */
    border-radius: 10px;  
    text-transform: none /* Rounded corners, adjust as needed */    /* Rounded corners, adjust as needed */
}

.whiteboardView .button-active-mute {
  background-color: #66C097; /* Green background */
  color: #fff;    
  font-weight: 400; 
  font-size: 12; 
  padding-left: 10vh;
  padding-right: 10vh;        /* Black text */
  border-radius: 10px;  
  margin-left: 3vh;
  text-transform: none /* Rounded corners, adjust as needed */    /* Rounded corners, adjust as needed */
}

.whiteboardView .button-error {
  background-color: #FF0000; /* Error red background */
  color: #fff;    
  font-weight: 400;
  margin-left: 3vh; 
  font-size: 12; 
  padding-left: 10vh;
  padding-right: 10vh;        /* Black text */
  border-radius: 10px;  
  text-transform: none /* Rounded corners, adjust as needed */    /* Rounded corners, adjust as needed */
}

.whiteboardView .syncWhiteboard {
    background-color: black; /* White background */
    color: white;          /* Black text */
    border-radius: 8px; 
    border-color: #fff;
    font-weight: 400; 
    font-size: 12; 
    padding-left: 10vh;
    padding-right: 10vh;  
    margin-top: 1px;      /* Black text */
    border-radius: 10px;  
    text-transform: none /* Rounded corners, adjust as needed */
}


.whiteboardView .button-waitlist {
  background-color: black; /* White background */
  color: white;           /* Black text */
  border-radius: 10px;  
  font-weight: 400; 
  font-size: 12; 
  padding-left: 12vh;
  padding-right: 12vh;
  text-align: center;
  text-transform: none; /* Rounded corners, adjust as needed */
  border: 1px solid #fff;
  margin-left: 3vh; 
}

.whiteboardView .button-sync {
  background-color: white; /* White background */
  color: black;           /* Black text */
  border-radius: 10px;  
  font-weight: 400; 
  font-size: 12; 
  padding-left: 12vh;
  padding-right: 12vh;
  text-align: center;
  text-transform: none; /* Rounded corners, adjust as needed */
  border: 1px solid #fff;
  margin-left: 3vh; 
}

.headerModified {
  color:#fff;
  text-align: left;
  padding-left: 30px; 
  font-weight: 400;
}


.custom-excalidraw .excalidraw {
  --color-primary: #fcc6d9;
  --color-primary-darker: #f783ac;
  --color-primary-darkest: #e64980;
  --color-primary-light: #f2a9c4;
  border-radius: 10px;
  border: 1px solid #E9E9E9;
}

#chat::-webkit-scrollbar {
  width: 10px;
  background: #1E1E1E;
}

#chat::-webkit-scrollbar-track {
  background: #1E1E1E; 
}

#chat::-webkit-scrollbar-thumb {
  background: #888; 
}

#chat::-webkit-scrollbar-thumb:hover {
  background: #555; 
}

  /* Testing Excalidraw override  .Excalidraw {
    --color-primary: blue!important;
    --color-primary-darker: blue !important;
    --color-primary-darkest:blue !important;
    --color-primary-light: blue !important;
    --default-bg-color: blue !important;
    min-height: 60vh;
    border: 5px solid yellow; 
} */

.space-mono-regular {
  font-family: "Mulish", monospace;
  font-weight: 400;
  font-style: normal;
}

.space-mono-regular-italic {
  font-family: "Mulish", monospace;
  font-weight: 400;
  font-style: italic;
}

.space-mono-bold { 
  font-family: "Mulish", monospace;
  font-weight: 700;
  font-style: normal;
}

.space-mono-bold-italic {
  font-family: "Mulish", monospace;
  font-weight: 700;
  font-style: italic;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

input:focus,
select:focus,
textarea:focus,
button:focus {
    outline: none;
}

.color-primary {
  color:#20C58A
}

.text-center {
  text-align: center;
}

.btn {
  cursor: pointer !important;
  background: #20C58A !important;
  text-transform: none !important;
  padding: 10px !important;
  border-radius: 10px !important;
  font-family: 'Inter' !important;
  color: white !important;
  border: none !important;
  font-weight: 600 !important;
}

.btn-upload {
  cursor: pointer !important;
  background-color: var(--color-lightgray) !important;
  text-transform: none !important;
  padding: 12px;
  border-radius: 8px !important;
  font-family: 'Inter' !important;
  font-size: 14px;
  line-height: 20px;
  height: 'fit-content' !important;
  color: var(--color-gray) !important;
  border: 1px dashed lightgray !important;
  box-shadow: none !important;
  font-weight: 500 !important;
  transition: background-color .2s;
}

.btn-wayfaster {
  background: var(--color-wayfaster) !important;
  font-family: 'EuclidCircularA' !important;
  color: black !important;
}

.btn-wayfaster:hover {
  background: var(--color-wayfaster-dark) !important;
}


.btn:hover {
  background-color: #17A974;
}

.btn:disabled, .btn.disabled {
  background-color: #CCCCCC !important;
  cursor: not-allowed !important;
}

.btn:active {
  background-color: #128C5E;
}

.btn.danger {
  background: red !important;
  color: white !important;
}

.btn-main {
  cursor: pointer !important;
  background-color: #0D0E14 !important;
  text-transform: none !important;
  padding: 6px 8px;
  border-radius: 8px !important;
  font-family: 'Inter' !important;
  font-size: 14px;
  line-height: 20px;
  height: 40px;
  color: white !important;
  border: none !important;
  font-weight: 500 !important;
  transition: all .2s;
}
.outlined.gray {
  background-color: white !important;
  border: 1px solid lightgray !important;
  font-weight: 600 !important;
}

.outlined.gray:hover:not([disabled]) {
  background-color: lightgray !important;
}

.outlined {
  background-color: #ECF5FB !important;
  color: #1a1a1a !important;
}

.btn-main:disabled {
  opacity: 0.5 !important;
  cursor: not-allowed !important;
  background-color: #303135 !important;
}

.btn-main.small {
  height: 24px;
  padding: 3px 4px;
}

.btn-main.danger {
  background-color: var(--color-red) !important;
}

.btn-main.danger:hover {
  background-color: var(--color-required) !important;
}

.btn-main:hover {
  background-color: #303135 !important;
}

.section-heading, .section-body, .input-label {
  margin: 0 0 8px 0;
}